.Learn-Chunk {
  margin: 10px;
  padding: 10px;
  border:1px solid silver;
  border-radius: 5px;
}

.Learn-Chunk-Line {
  text-align: left;
  padding: 5px;
  margin: 5px;
}

.Learn-Chunk-IsCharacter {
  font-weight: bold;
}

.Learn-Character {
  background-color: #eee;
  padding: 5px;
  border-radius: 5px;
  margin-right: 10px;
}

.Learn-Line {
  line-height: 2;
}
