.Auth-SampleUI {
  border: 1px solid black;
  padding: 10px;
  border-radius: 10px;
}

.Auth-SampleUI-Title {
  padding: 10px;

}

.Auth-Footer {
  margin-top: 25px;
}

.Auth-Logo {
  background-color: rgb(97,86,221);
}

.Auth-Text {
  line-height: 2;
  font-size: 30px;
  border:1px solid black;
  border-radius: 10px;
  margin-top: 20px;
  padding: 10px;
}

.Auth-List {
  font-size: 20px;
  text-align: left;
}
