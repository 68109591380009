.SceneFormat {
 background-color: rgb(237, 247, 237);
 margin-bottom: 10px;
 border-radius: 10px;
 padding: 15px;
 text-align: left;
 overflow: scroll;
}

.SceneFormat-Text {
  text-align: left;
}
