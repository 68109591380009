.Practice {
  margin: auto;
}

.Practice-Buttons {
  text-align: center;
  /* margin-top: 50px; */
}

.Practice-Form {
  text-align: left;
}

.Practice-LinearProgress {
  margin-top: 10px;
  margin-bottom: 10px;
}

.Practice-Position {
  background-color: #eee;
  border-radius: 25px;
  width: 40px;
  margin: auto;
  padding: 5px;
  margin-bottom: 5px;
}
