body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.buttons-pull-right {
  position: absolute;
  top:15px;
  right: 15px;
  width: 120px;
  transform: translateY(-50%);
}

.p-relative {
  position: relative;
}

.List {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.List li {
  padding: 15px;
  text-align:left;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
}

.List > li:nth-of-type(odd) {
  background-color: #e8f4fd ;
}

.List > li:last-child {
  border-bottom:1px solid gray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.List > li:first-child {
  border-top:1px solid gray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
