.ValidateScene ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.ValidateScene li {
  text-align: left;
  padding: 10px;
}

.ValidateSceneAccordion {
  background-color: rgb(253, 237, 237);
}
