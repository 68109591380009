/* .Play-Scenes-List {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.Play-Scenes-List li {
  padding: 15px;
  text-align:left;
  border-left: 1px solid gray;
  border-right: 1px solid gray;
} */

.Play-Scenes {
  width: 100%;
  margin-top: 20px;
}

/* .Play-Scenes-List.striped-list > li:nth-of-type(odd) {
  background-color: #e8f4fd ;
}

.Play-Scenes-List.striped-list > li:last-child {
  border-bottom:1px solid gray;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.Play-Scenes-List.striped-list > li:first-child {
  border-top:1px solid gray;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
} */
