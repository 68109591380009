.Line {
 text-align: center;
 margin-bottom: 15px;
 margin-top: 25px;
 border:1px solid silver;
 border-radius: 10px;
 /* width: 50%; */
 /* margin: auto; */
}

.Line-Character {
  font-weight: bold;
  border-bottom: 1px solid silver;
}

.Line-Text {
  padding: 10px;
}
