/* .Plays-Plays-List {
  list-style: none;
  margin: 0px;
  padding: 0px;
  width:100%;
}

.Plays-Plays-List li {
  padding: 10px;
  margin: 5px;
  text-align: left;
}

.Plays-Plays {
  width: 100%;
  margin: auto;
  border:1px solid gray;
  border-radius: 10px;
  margin-top: 20px;
} */

.Plays-Plays {
  margin-top: 20px;
}

.Plays {
  width: 100%;
}
