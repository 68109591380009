.PlayEntry-Buttons {
   position: absolute;
   top:50%;
   right: 0;
   width: 120px;
   transform: translateY(-50%);
}

.PlayEntry {
  position: relative;
  text-align: left;
}

.PlayEntry-Text {
  margin-bottom: 30px;
}
